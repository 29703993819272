import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [UserService],
})
export class UserModule {}
