import { Action, createReducer, on } from '@ngrx/store';
import {
  initialState,
  ManagementState,
} from '@app/management/state/management.state';
import * as actions from '../state/management.actions';

const reducer = createReducer<ManagementState, Action>(
  initialState,

  on(actions.schedulePensionJob, (state) => ({
    ...state,
    isSchedulingPensions: true,
  })),
  on(
    actions.schedulePensionJobSuccess,
    actions.schedulePensionJobFailure,
    (state) => ({
      ...state,
      isSchedulingPensions: false,
    }),
  ),

  on(actions.scheduleRebateCalculation, (state) => ({
    ...state,
    isSchedulingRebate: true,
  })),
  on(
    actions.scheduleRebateCalculationSuccess,
    actions.scheduleRebateCalculationFailure,
    (state) => ({
      ...state,
      isSchedulingRebate: false,
    }),
  ),

  on(actions.scheduleReinsuranceEventFieldSync, (state) => ({
    ...state,
    isSchedulingReinsuranceEventFieldSync: true,
  })),
  on(
    actions.scheduleReinsuranceEventFieldSyncSuccess,
    actions.scheduleReinsuranceEventFieldSyncFailure,
    (state) => ({
      ...state,
      isSchedulingReinsuranceEventFieldSync: false,
    }),
  ),

  on(actions.updateRebateFees, (state) => ({
    ...state,
    isUpdatingRebateFees: true,
  })),
  on(
    actions.updateRebateFeesSuccess,
    actions.updateRebateFeesFailure,
    (state) => ({
      ...state,
      isUpdatingRebateFees: false,
    }),
  ),

  on(actions.loadRebateControls, (state) => ({
    ...state,
    rebateControls: {
      ...state.rebateControls,
      isLoading: true,
    },
  })),
  on(actions.loadRebateControlsSuccess, (state, { response }) => ({
    ...state,
    rebateControls: {
      ...state.rebateControls,
      data: response,
      isLoading: false,
    },
  })),
  on(actions.loadRebateControlsFailure, (state, { error }) => ({
    ...state,
    rebateControls: {
      ...state.rebateControls,
      data: null,
      isLoading: false,
      error,
    },
  })),

  on(actions.scheduleStatementGeneration, (state) => ({
    ...state,
    isSchedulingStatementGeneration: true,
  })),
  on(
    actions.scheduleStatementGenerationSuccess,
    actions.scheduleStatementGenerationFailure,
    (state) => ({
      ...state,
      isSchedulingStatementGeneration: false,
    }),
  ),
);

export const managementReducer = (state: ManagementState, action: Action) =>
  reducer(state, action);
