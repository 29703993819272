/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG } from '@app/core/services/app-config/app-config.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const init = async (config: any) => {
  await platformBrowserDynamic([
    {
      provide: APP_CONFIG,
      useValue: config,
    },
  ])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

  if ('serviceWorker' in navigator && environment.serviceWorkerEnabled) {
    navigator.serviceWorker.register('ngsw-worker.js');
  }
};

fetch(`${environment.serverUrl}assets/app-config.json`)
  .then((response) => response.json())
  .then((config: any) => {
    init(config);
  });
