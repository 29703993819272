<div class="side-nav-icon" [class.expanded]="expanded">
  <span class="fem-icon {{ icon }}" [class.active]="isActive">
    @if (hasNotifications) {
      <div
        class="translate-middle badge rounded-pill bg-danger notification-pill">
        {{ getNotificationCount() }}
      </div>
    }
  </span>
</div>
<div class="side-nav-text" [class.expanded]="expanded">
  <span [class.hidden]="!expanded" [class.active]="isActive">{{ label }}</span>
</div>
