import { ApiData, createApiDataInitialState } from '@app/core/models/api-data';
import { RebateControl } from '@app/management/models/responses/rebate-control';

export interface ManagementState {
  isSchedulingPensions: boolean;
  isSchedulingRebate: boolean;
  isSchedulingReinsuranceEventFieldSync: boolean;
  isUpdatingRebateFees: boolean;
  rebateControls: ApiData<RebateControl[]>;
  isSchedulingStatementGeneration: boolean;
}

export const initialState: ManagementState = {
  isSchedulingPensions: false,
  isSchedulingRebate: false,
  isSchedulingReinsuranceEventFieldSync: false,
  isUpdatingRebateFees: false,
  rebateControls: createApiDataInitialState(),
  isSchedulingStatementGeneration: false,
};
