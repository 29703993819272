@for (toast of toastService.toasts; track toast) {
  <ngb-toast
    [class]="toast.className"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast"></ng-template>
    </ng-template>

    <ng-template #text>
      {{ toast.textOrTpl }}

      @if (toast.formErrors && toast.formErrors.length > 0) {
        <ul class="mb-1">
          @for (error of toast.formErrors; track error) {
            <li>{{ error }}</li>
          }
        </ul>
      }
    </ng-template>
  </ngb-toast>
}
