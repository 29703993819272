import { Component, OnInit } from '@angular/core';
import { AuthenticationFacade } from '@app/authentication/state/authentication.facade';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { TimeoutModalComponent } from './components/timeout-modal/timeout-modal.component';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent extends AutoUnsubscription implements OnInit {
  modal: any;
  countDown: any;
  showDashboard$: Observable<boolean>;

  constructor(
    private idle: Idle,
    private authenticationFacade: AuthenticationFacade,
    private modalService: NgbModal,
    private appConfig: AppConfigService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.showDashboard$ = combineLatest([
      this.authenticationFacade.isLoading$,
      this.authenticationFacade.isAuthenticated$,
    ]).pipe(
      map(([isLoading, isAuthenticated]) => !isLoading && isAuthenticated),
    );

    this.idle.setIdle(this.appConfig.config.timeOut);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setTimeout(10);
    this.idle.onTimeout.subscribe(() => this.authenticationFacade.logout());
    this.idle.onIdleStart.subscribe(() => {
      this.open();
    });
    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.countDown = countdown;
      this.modal.componentInstance.countDown = countdown;
    });
    this.idle.watch();
  }

  open() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(TimeoutModalComponent);
    this.modal = modalRef;
  }
}
