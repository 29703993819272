import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppConfig } from '@app/core/services/app-config/app-config';
import {
  APP_CONFIG,
  AppConfigService,
} from '@app/core/services/app-config/app-config.service';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { Logger } from '@app/core/services/logger.service';

import { InteractionType } from '@azure/msal-browser';

export const loggerCallback = (
  logLevel: LogLevel,
  message: string,
  containsPii: boolean,
  logger: Logger,
) => {
  if (containsPii) {
    return;
  }
  switch (logLevel) {
    case LogLevel.Error:
      logger.error(new Error(message));
      return;
    case LogLevel.Info:
      logger.info(message);
      return;
    case LogLevel.Verbose:
      logger.debug(message);
      return;
    case LogLevel.Trace:
      logger.debug(message);
      return;
    case LogLevel.Warning:
      logger.warn(message);
      return;
  }
};

export const msalInstanceFactory = (
  config: AppConfig,
): IPublicClientApplication => {
  const msalConfig = config.msal;
  const logger = new Logger('Msal');
  return new PublicClientApplication({
    auth: msalConfig.config.auth,
    cache: msalConfig.config.cache,
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) =>
          loggerCallback(level, message, containsPii, logger),
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: msalConfig.logging,
      },
    },
  });
};

const msalInterceptorConfigFactory = (
  config: AppConfig,
): MsalInterceptorConfiguration => {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    'https://graph.microsoft.com/v1.0/me',
    config.msal.angularConfig.consentScopes,
  );

  const bokamosoApiScope =
    config.msal.angularConfig.protectedResourceMap.bokamosoApi;
  protectedResourceMap.set(
    bokamosoApiScope.endpoint,
    bokamosoApiScope.consentScopes,
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
};

export const msalGuardConfigFactory = (
  config: AppConfig,
): MsalGuardConfiguration => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: config.msal.angularConfig.consentScopes,
  },
  loginFailedRoute: '/',
});

@NgModule({
  imports: [MsalModule],
})
export class MsalConfigModule {
  static forRoot() {
    return {
      ngModule: MsalConfigModule,
      providers: [
        AppConfigService,
        {
          provide: MSAL_INSTANCE,
          useFactory: msalInstanceFactory,
          deps: [APP_CONFIG],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: msalGuardConfigFactory,
          deps: [APP_CONFIG],
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: msalInterceptorConfigFactory,
          deps: [APP_CONFIG],
        },
        MsalBroadcastService,
        MsalGuard,
        MsalService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
      ],
    };
  }
}
