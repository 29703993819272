<header>
  <nav class="navbar fixed-top navbar-expand-lg">
    <a [routerLink]="'/'" class="navbar-left"
      ><img src="assets/images/logo_transparent.png" alt="Brand Logo"
    /></a>
    <div class="ms-4 mt-2">
      <div>
        <div class="navbar-name">
          <span *ngIf="name">{{ name | titlecase }}</span>
        </div>
      </div>

      <div>
        <div class="navbar-jobtitle" id="navbar-name">
          <span *ngIf="jobTitle">
            {{ jobTitle | titlecase }}
          </span>

          <span
            ngbTooltip="Your approximate internet speed is {{
              displaySpeed
            }} Mb/s"
            *ngIf="currentSpeed !== null"
            class="connection-indicator badge rounded-pill text-bg-{{
              getSpeedClass()
            }}">
          </span>

          <a
            class="badge rounded-pill text-bg-light"
            [routerLink]="'dashboard/changelog'">
            v{{ appVersion }}
          </a>

          <div
            *ngIf="shouldDisplayDeploymentEnvironment"
            class="badge rounded-pill text-bg-light">
            {{ deploymentEnvironment }}
          </div>

          <img
            *ngIf="buildIndicatorUrl"
            class="build-indicator"
            [src]="buildIndicatorUrl" />

          <img
            *ngIf="unitTestIndicatorUrl"
            class="build-indicator"
            [src]="unitTestIndicatorUrl" />
        </div>
      </div>
    </div>

    <div class="navbar-nav ms-auto">
      <a class="nav-item nav-link">
        <span *ngIf="displayNotifications" id="notification-circle">{{
          1
        }}</span>
        <span translate class="navbar-text" (click)="logout()">Log out</span>
      </a>
    </div>
    <div class="navbar-nav">
      <div class="nav-item" ngbDropdown display="dynamic">
        <a id="user-dropdown" class="nav-link" ngbDropdownToggle> </a>
        <div ngbDropdownMenu aria-labelledby="user-dropdown">
          <h6 class="dropdown-header">
            <span translate>Logged in as </span><strong>{{ username }}</strong>
          </h6>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" (click)="logout()" translate>
            Logout
          </button>
        </div>
      </div>
    </div>
  </nav>
</header>
