import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
})
export class NavigationButtonComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  expanded = false;

  @Input()
  icon = '';

  @Input()
  isActive = false;
  @Input()
  hasNotifications = false;

  @Input()
  notificationCount: number;

  constructor() {}

  ngOnInit(): void {}

  getNotificationCount() {
    return this.notificationCount > 99
      ? '99+'
      : this.notificationCount.toString();
  }
}
