import { Component, Input } from '@angular/core';
import { AuthenticationFacade } from '@app/authentication/state/authentication.facade';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss'],
})
export class TimeoutModalComponent {
  @Input() countDown: number;

  constructor(
    public activeModal: NgbActiveModal,
    private authenticationFacade: AuthenticationFacade,
  ) {}

  logout() {
    this.authenticationFacade.logout();
  }
}
