import { HttpService } from '@app/core';
import { Injectable } from '@angular/core';
import { SchedulePensionsJobRequest } from '@app/management/models/requests/schedule-pension-job-request';
import { ScheduleRebateCalculationJobRequest } from '@app/management/models/requests/schedule-rebate-calculation-job-request';
import { ScheduleStatementGenerationRequest } from '@app/management/models/requests/schedule-statement-generation-request';
import { parseGlPeriodToNumber } from '@app/shared/form-inputs/gl-period-input/gl-period';
import { UpdateRebateFeesRequest } from '@app/management/models/requests/update-rebate-fees-request';
import { RebateControl } from '@app/management/models/responses/rebate-control';

@Injectable({ providedIn: 'root' })
export class ManagementService {
  constructor(private http: HttpService) {}

  schedulePensionMonthEnd = (request: SchedulePensionsJobRequest) =>
    this.http.patch(`pensions/pensions/job`, request);

  scheduleRebateCalculation = (request: ScheduleRebateCalculationJobRequest) =>
    this.http.patch(`underwriting/policyrebates/job`, request);

  scheduleReinsuranceEventFieldSync = () =>
    this.http.patch(`finance/reinsurance-claims/job`, {});

  scheduleStatementGeneration = (request: ScheduleStatementGenerationRequest) =>
    this.http.post(`underwriting/policystatements/schedule`, {
      glPeriod: parseGlPeriodToNumber(request.glPeriod),
    });

  updateRebateFees = (request: UpdateRebateFeesRequest) =>
    this.http.patch(
      `underwriting/policyrebates/control/${request.rebateAssessmentYear}`,
      request,
    );

  getRebateControls = () =>
    this.http.get<RebateControl[]>(`underwriting/policyrebates/control/`);
}
