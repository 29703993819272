<div
  ngbNavItem
  class="sidebar-item"
  routerLinkActive
  #routeIsActive="routerLinkActive"
  [class.active-item]="routeIsActive.isActive"
  [class.expanded]="expanded">
  <ng-container *ngIf="!route && !highlightRoute">
    <a>
      <app-navigation-button
        [label]="label"
        [icon]="icon"
        [expanded]="expanded"
        [isActive]="routeIsActive.isActive"
        [notificationCount]="notificationCount"
        [hasNotifications]="hasNotifications">
      </app-navigation-button>
    </a>
  </ng-container>

  <ng-container *ngIf="route && openNewTab">
    <a class="d-none" [routerLinkActive]="highlightRoute"></a>
    <a [target]="'_blank'" [href]="route">
      <app-navigation-button
        [label]="label"
        [icon]="icon"
        [expanded]="expanded"
        [isActive]="routeIsActive.isActive"
        [notificationCount]="notificationCount"
        [hasNotifications]="hasNotifications">
      </app-navigation-button>
    </a>
  </ng-container>

  <ng-container *ngIf="route && !openNewTab">
    <a [routerLink]="route">
      <app-navigation-button
        [label]="label"
        [icon]="icon"
        [expanded]="expanded"
        [isActive]="routeIsActive.isActive"
        [notificationCount]="notificationCount"
        [hasNotifications]="hasNotifications">
      </app-navigation-button>
    </a>
  </ng-container>
</div>
