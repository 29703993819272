import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { NotFoundComponent } from './core/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

  Shell.childRoutes([
    {
      path: 'dashboard',
      loadChildren: () =>
        import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
      path: 'registry',
      loadChildren: () =>
        import('./registry/registry.module').then((m) => m.RegistryModule),
    },
    {
      path: 'claims-administration',
      loadChildren: () =>
        import('./claims-administration/claims-administration.module').then(
          (m) => m.ClaimsAdministrationModule,
        ),
    },
    {
      path: 'underwriting',
      loadChildren: () =>
        import('./underwriting/underwriting.module').then(
          (m) => m.UnderwritingModule,
        ),
    },
    {
      path: 'finance',
      loadChildren: () =>
        import('./finance/finance.module').then((m) => m.FinanceModule),
    },
    {
      path: 'letters',
      loadChildren: () =>
        import('./letters/letters.module').then((m) => m.LettersModule),
    },
    {
      path: 'pensions',
      loadChildren: () =>
        import('./pensions/pensions.module').then((m) => m.PensionsModule),
    },
    {
      path: 'education-assistance',
      loadChildren: () =>
        import('./education-assistance/education-assistance.module').then(
          (m) => m.EducationAssistanceModule,
        ),
    },
    {
      path: 'mi-stats',
      loadChildren: () =>
        import('./mi-stats/mi-stats.module').then((m) => m.MiStatsModule),
    },
    {
      path: 'support',
      loadChildren: () =>
        import('./support/support.module').then((m) => m.SupportModule),
    },
    {
      path: 'human-resources',
      loadChildren: () =>
        import('./human-resources/human-resources.module').then(
          (m) => m.HumanResourcesModule,
        ),
    },
    {
      path: 'communications',
      loadChildren: () =>
        import('./communications/communications.module').then(
          (m) => m.CommunicationsModule,
        ),
    },
  ]),

  { path: '404', component: NotFoundComponent },
  { path: 'auth-callback', redirectTo: 'dashboard', pathMatch: 'full' },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
