<div class="modal-header">
  <h4 class="modal-title">Session Timeout!</h4>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss('Cross click')"
    aria-label="Close"></button>
</div>
<div class="modal-body">
  <p>
    Your session is about to expire. You will be automatically signed out in
    <strong>{{ countDown }}</strong>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="logout()">
    Logout
  </button>
  <button
    *ngIf="countDown > 0"
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')">
    Continue Session
  </button>
</div>
