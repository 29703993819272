import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import Handlebars from 'handlebars';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';

@Injectable({ providedIn: 'root' })
export class BokamosoTitleStrategy extends TitleStrategy {
  constructor(
    private titleService: Title,
    private appConfig: AppConfigService,
  ) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    const baseTitle = this.buildTitle(snapshot);
    const template = Handlebars.compile(baseTitle);
    let params = {};
    let route = snapshot.root;
    do {
      params = {
        ...params,
        ...route.params,
        ...route.data,
        ...route.queryParams,
      };
      route = route.firstChild;
    } while (route);

    this.titleService.setTitle(template(params));
  }

  override buildTitle(snapshot: RouterStateSnapshot): string | undefined {
    return super.buildTitle(snapshot) ?? this.appConfig.config.title;
  }
}
