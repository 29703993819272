<nav id="sidebar" [class.active]="activeClass">
  <ul ngbNav #nav="ngbNav" class="list-unstyled components">
    <app-navigation-item
      *ngIf="dashboardEnabled"
      [expanded]="!activeClass"
      icon="fem-icon-user-circle"
      [label]="'Dashboard'"
      route="/dashboard"
      highlightRoute="/dashboard"></app-navigation-item>
    <app-navigation-item
      *ngIf="miStatsEnabled"
      [expanded]="!activeClass"
      icon="fem-icon-stats-dots"
      [label]="'MI Stats'"
      route="/mi-stats/reporting-overview"
      highlightRoute="/mi-stats"></app-navigation-item>
    <app-navigation-item
      *ngIf="imageApprovalEnabled"
      [expanded]="!activeClass"
      icon="fem-icon-picture"
      [label]="'Image'"
      route="/registry/image-approval/-1"
      highlightRoute="/registry"></app-navigation-item>
    <app-navigation-item
      *ngIf="policyListsEnabled"
      [expanded]="!activeClass"
      icon="fem-icon-list"
      [label]="'Policy Lists'"
      route="/underwriting/policy-lists"
      highlightRoute="/underwriting/policy-lists"></app-navigation-item>
    <app-navigation-item
      *ngIf="uploadEnabled"
      (click)="openUploadModal()"
      [expanded]="!activeClass"
      icon="fem-icon-upload"
      [label]="'Upload'"></app-navigation-item>
    <app-navigation-item
      *ngIf="financeEnabled"
      [expanded]="!activeClass"
      icon="fem-icon-finance"
      [label]="'Finance'"
      route="/finance"
      highlightRoute="/finance"></app-navigation-item>
    <app-navigation-item
      *ngIf="supportEnabled"
      [expanded]="!activeClass"
      icon="fem-icon-complaints"
      [label]="'Support Portal'"
      route="/support"
      highlightRoute="/support"></app-navigation-item>
    <app-navigation-item
      *ngIf="humanResourceEnabled"
      [expanded]="!activeClass"
      icon="fem-icon-general"
      [label]="'People'"
      route="/human-resources"
      highlightRoute="/human-resources"></app-navigation-item>
    <app-navigation-item
      *ngIf="communicationEnabled"
      [expanded]="!activeClass"
      icon="fem-icon-whatsapp"
      [label]="'Communications'"
      route="/communications/whatsapp-chat"
      highlightRoute="/communications/whatsapp-chat"
      [hasNotifications]="
        communicationNotificationsEnabled &&
        ((unreadWhatsAppNotifications$ | async)?.conversationCount ?? 0) > 0
      "
      [notificationCount]="
        (unreadWhatsAppNotifications$ | async)?.conversationCount ?? 0
      "></app-navigation-item>
    <div
      class="open-close-icon"
      (click)="toggleClass()"
      [class.small]="activeClass">
      <span
        class="fem-icon side-nav-icon"
        [class.fem-icon-caret-right]="activeClass"
        [class.fem-icon-caret-left]="!activeClass"></span>
    </div>

    <div class="settings-section">
      <form
        #hangfireForm
        class="d-none"
        method="POST"
        [action]="hangfireDashboardUrl">
        <!-- <input
          type="hidden"
          name="access_token"
          [value]="this.authService.apiToken"
        /> -->
        <button></button>
      </form>
      <app-navigation-item
        *ngIf="hangfireDashboardEnabled"
        [label]="'Hangfire'"
        (click)="hangfireForm.submit()"
        [expanded]="!activeClass"
        [icon]="'fem-icon-calender'"
        [openNewTab]="true"></app-navigation-item>

      <app-navigation-item
        *ngIf="changeLogEnabled"
        [label]="'Changelog'"
        [route]="'dashboard/changelog'"
        [expanded]="!activeClass"
        [icon]="'fem-icon-notification'"></app-navigation-item>

      <app-navigation-item
        *ngIf="helpEnabled"
        [expanded]="!activeClass"
        [icon]="'fem-icon-help-circle'"
        [label]="'Help'"
        [route]="'/help'"></app-navigation-item>
    </div>
  </ul>
</nav>
